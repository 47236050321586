@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


:root{
  /*Kino color code*/
  --Kino_dark_green: #264432;
  --Kino_LightDark_green:#80AC93;
  --Kino_dark:#484848;
  --Kino_LightGreen:#A0E8DA;

  --font-1: 'Poppins', sans-serif;
  --font-2: 'DM Sans', sans-serif;
}


/* Affects globally*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  background-color: #fff;
  /*Heading*/
  h1 {
    font-size: 100px;
  }

  h2 {
    font-size: 68px;
  }

  h3 {
    font-size: 56px;
    color:var(--Kino_LightDark_green);
  }

  h4 {
    font-size: 32px;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 18px;
  }

  ul {
    list-style: none;
  }

  img {
    object-fit: cover;
  }

  @media only screen and (max-width:1340px) {
    h1 {
      font-size: 58px;
    }
  
    h2 {
      font-size: 68px;
    }
  
    h3 {
      font-size: 40px;
      color:var(--Kino_LightDark_green);
    }
  
    h4 {
      font-size: 32px;
    }
  
    h5 {
      font-size: 24px;
    }
  
    h6 {
      font-size: 15px;
    }
  
    ul {
      list-style: none;
    }
  
    img {
      object-fit: cover;
    }

  }

  @media screen and (min-width: 991px) and (max-width: 1200px) {
    h1 {
      font-size: 58px;
    }
  
    h2 {
      font-size: 68px;
    }
  
    h3 {
      font-size: 30px;
      color:var(--Kino_LightDark_green);
    }
  
    h4 {
      font-size: 25px;
    }
  
    h5 {
      font-size: 24px;
    }
  
    h6 {
      font-size: 15px;
    }

  }
  @media only screen and (max-width:993px) {
    h1 {
        font-size: 48px;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 17px;
    }

    h6 {
        font-size: 12px;
    }
  }

  a, Link{
    text-decoration: none;
    color: white;
  }
}

/* Background color */
.bg-color{
  background-color: var(--Kino_LightDark_green);
}
/* font class */
.font-1{
  font-family: var(--font-1);
}
.font-2{
  font-family: var(--font-2);
}

.l_space{
  letter-spacing: 5px;
}

.progressBar{
  background-color: #80AC93;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.text-sm-justify{
  text-align: center;
}
@media screen and (max-width: 576px) {
  .text-sm-justify{
    text-align: justify;
    text-justify: inter-word;
  }
}

.text-sm-justify-Blog{
  text-align: left;
}
@media screen and (max-width: 576px) {
  .text-sm-justify-Blog{
    text-align: justify;
    text-justify: inter-word;
  }
}

body {
    margin: 0;
    padding:0;
    box-sizing: border-box;
    background: #fff;
  }

  .extraSmallFont {
    font-size: 12px;
    border-bottom: 2px solid transparent;
  }

  .SmallFont {
    font-size: 14px;
    border-bottom: 2px solid transparent;
  }

/*Kino Button */
#KinoBtn {
  background: #80AC93;
  background: linear-gradient(to left, #80AC93 50%, #6b8f7a 50%) right;
  background-size: 205%;
  transition: 0.5s ease-out;
}
#KinoBtn:hover {
  background-position: left;
}
#KinoBtn i{
  color: white;
  font-size: 24px;
}

/*Banner of each page */
#Banner{
  background: linear-gradient(270deg, #02010100 25%, #ecf9eccc 55%) , url("../../Resources/Images/banner1.png") fixed center center;
  background-size: cover;
  padding:0;
  margin: 0;

  a, Link{
    text-decoration: none;
    color: black;
  }
}


/* NavBar */
.LightDark_Green{
  color:var(--Kino_LightDark_green);
}
.FillKinoGreen{
  filter: invert(70%) sepia(12%) saturate(684%) hue-rotate(93deg) brightness(90%) contrast(88%);
}
#header{
  background-color: #fff;
  .LightDark_Green{
    color:var(--Kino_LightDark_green);
  }
  .LightDark_Green_Bg{
    background-color: var(--Kino_LightDark_green);
  }
  a{
    color: var(--Kino_dark_green);
  }

  #verticalbar {
    height: 40px;
    width : 1px;
    background: #97a2b0;
  }

  .changeWidth {
    width: 36px;
  }

  .iconChange:after {
    content: "";
    display: block;
    margin: auto;
    height: 3px;
    width: 0px;
    background: transparent;
    transition: all 0.5s ease;
  }

  .iconChange:hover::after {
    width: 100%;
    background: #80AC93;
  }

  .iconHeight {
    height: 22px;
    width: 22px;
  }

  .iconChange:hover a {
    color: #80AC93;
  }

  .iconChange img {
    transition: all 500ms;
  }

  .iconChange:hover img {
    transform: translateX(-180%);
    transform-origin: right;
    transition: transform 500ms ease-in-out;
  }

  #loginBtn {
    background: #80AC93;
    background: linear-gradient(to left, #80AC93 50%, #6b8f7a 50%) right;
    background-size: 205%;
    transition: 0.5s ease-out;
  }

  #loginBtn:hover {
    background-position: left;
  }

  #iconContainer {
    height: 35px;
    width: 35px;
  }

  .Home{
    padding: 10px;
    color: #fff;
    border-radius: 50%;
    background-color: var(--Kino_LightDark_green);
  }

  .fs-7{
    font-size: 16px;
  }
  @media screen and (min-width: 991px) and (max-width: 1350px) {
    .fs-7{
      font-size: 13px;
    }
  }

  /* The following codes are for toggler */
  button{
    background-color: #26443200;
  }
  .togglerIcon{
    font-size: 20px;
  }
}


/* LANDING PAGE / HOMPAGE */
/* Hero Section */
#hero{
  background: linear-gradient(rgba(0, 0, 0, 0.35), rgb(0, 0, 0,0.5)), url("../../Resources/Images/background/kinofrontdeskk.jpg") fixed center center;
  background-size: cover;
  padding: 100px 0; 

  .container{
    padding-top: 40px 40px;
  }
  a,Link{
    font-family: var(--font-1);
    max-width: 220px;
  }
  .head{
    font-family: var(--font-2);
    color: #ffffff;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  h2{
    font-family: var(--font-1);
    color:var(--Kino_LightGreen);
  }

  @media screen and (max-width: 576px) {
    .head{
      font-size: 14px;
    }
  }
  
}

/* Services and Therapist - SandP */
#Sandp{
    margin-top: -80px;

  .box-1{
    background-color: var(--Kino_LightDark_green);
  }
  .box-2{
    background-color: var(--Kino_LightDark_green);
  }

  @media screen and (max-width: 992px) {
      margin-top: -25px;
  }
}

/* About */
#About{
  
  .tagBox{
    background-color: var(--Kino_LightDark_green);
  }
  .black-and-white {
    filter: grayscale(100%);
  }
  #care{
    width:  8rem;
    height: 6.5rem;

    img{
      width: 4.5rem;
      filter: brightness(0) invert(1) grayscale(1);
      }
  }
  @media screen and (max-width: 576px) {
    #care{
      width:  6rem;
      height: 5rem;
  
      img{
        width: 3rem
        }
    }
  }
}


  /* Experience */
  #Experience{
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../Resources/Images/background/kinofrontdeskk.jpg") fixed center center;
    background-size: cover;
    padding: 100px 0;

    span{
      color:#fff;
    }
  }

  /* Choose */
  #Choose{
    margin-top: -100px;

    .choose-bg{
      background-color:aliceblue;
      /*box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);*/
    }
    .progressBar{
      background: var(--Kino_LightDark_green);
      background: linear-gradient(to left, var(--Kino_LightDark_green) 50%, var(--Kino_LightDark_green) 50%) right;
      background-size: 205%;
      transition: 0.5s ease-out;

    }

    .progressbar{
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-color: #eee;
    }

    .progressPercent{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      color: #fff;
     /* text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555; */
    }

    i{
      color: var(--Kino_LightDark_green);
    }
    img{
      width: 100%;
      height: 25rem;
    }
  }

  /* Review */
  #Review{
    img{
      width:90%;
      height: 40rem;
      float: right;
      }
    .limit_width{
      width: 650px;
    }
    .swiper {
      width: 120%;
      height: 100%;
      background: #eee;
    }
    
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #eee;
    
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .mySwiper{
      background: rgba(76, 175, 80, 0.0); /*To remove the background on the slidder*/
    }
    
    @media screen and (max-width: 992px) {
      img{
        width: 100%;
      }
      .swiper {
        width: 100%;
      }
    }
  }


/* Free Consultation */
#FreeConsultation{
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../Resources/Images/background/kinofrontdeskk.jpg") fixed center center;
  background-size: cover;
  padding: 70px 0;
}


/* Landing Blog */
#LandingBlog {
  .with-border-bottom{
    border-bottom: 5px solid #264432;
  }
  .bg-color{
    background-color: var(--Kino_LightDark_green);
  }
}

/* other useful */
.horizontalBar {
  max-width: 300px;
  height: 2px;
  background: #A0E8DA;
}

.extraSmallFont.active {
  border-bottom: 2px solid #6195f5
}

/* -- END -- LANDING PAGE / HOMPAGE */


/* Footer */
#Footer{
	background-color:#484848;
	
  img{
	padding: 0;
	margin: 0;
	width: auto;
	height: 120px;
  }
  i{
    color:#fff;
  }
  ul{
    list-style-type: none;
    padding: 0;
    margin:0;
  }
  li{
    padding: 0px 0px 10px 0px;
  }
  a{
    text-decoration: none;
    font-family: var(--font-1);
    color: #fff;
    font-weight: 300;
  }
  .social-container a i{
    padding:0px 10px;
    font-size: 25px;
  }
}


/* Banner Section */ /*Needs additional work */
#BestBanner {
  background: linear-gradient(270deg, #02010100 20%, hsl(145, 66%, 94%) 70%) , url("") fixed center right;
  background-size:contain;
  background-repeat: no-repeat;
  padding:0;
  margin: 0;
  
  a, Link{
    text-decoration: none;
    color: black;
  }
}

@media (min-width: 992px) { /* Adjust the breakpoint as needed */
  #BestBanner {
    background: linear-gradient(270deg, #02010100 20%, hsl(145, 66%, 94%) 80%) , url("../../Resources/Images/KinoLogo_2.png") fixed center right;
    background-size:contain;
    background-repeat: no-repeat;
    padding:0;
    margin: 0;
  }
}

#About .B_start{
  border-left: 5px solid #264432;
}
#AboutUs_Section4{
margin-top: -85px;

}

#AboutUs_Secton5{

  figure.team {
      position: relative;
      overflow: hidden;
      text-align: center;
    }
  figure.team * {
      -webkit-box-sizing: border-box;
          box-sizing: border-box;
    }
  
  figure.team img {
      max-width: 100%;
      opacity: 1;
      width: 100%;
      -webkit-transition: opacity 0.35s;
      transition: opacity 0.35s;
    }
  
  
  figure.team figcaption {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 30px 3em;
      width: 100%;
      height: auto;
    }
  figure.team figcaption::before {
      position: absolute;
      top: 150px;
      right: 30px;
      bottom: 30px;
      left: 100%;
      border-left: 4px solid #80AC93;
      content: '';
      opacity: 0;
      background-color: #80AC93;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      -webkit-transition-delay: 0.6s;
      transition-delay: 0.6s;
    }
  
  figure.team h4,
  figure.team p,a {
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s,-webkit-transform 0.35s,-moz-transform 0.35s,-o-transform 0.35s,transform 0.35s;
  }
  
  figure.team h4 {
      margin-top: 140px;
      margin-bottom: 20px;
    word-spacing: -0.15em;
    font-weight: 300;
    font-size: 20px;
    text-transform: uppercase;
    -webkit-transform: translate3d(30%, 0%, 0);
    transform: translate3d(30%, 0%, 0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  figure.team h4 span {
    font-weight: 800;
    font-size: 36px;
  }
  figure.team p,a {
      font-weight: 200;
      -webkit-transform: translate3d(0%, 30%, 0);
      transform: translate3d(0%, 30%, 0);
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
  
  figure.team:hover img {
      opacity: 0.6;
    }
  figure.team:hover figcaption h4 {
      opacity: 1;
      -webkit-transform: translate3d(0%, 0%, 0);
      transform: translate3d(0%, 0%, 0);
      -webkit-transition-delay: 0.4s;
      transition-delay: 0.4s;
    }
  figure.team:hover figcaption a,p {
      opacity: 0.9;
      -webkit-transform: translate3d(0%, 0%, 0);
      transform: translate3d(0%, 0%, 0);
      -webkit-transition-delay: 0.6s;
      transition-delay: 0.6s;
    }
  
  figure.team:hover figcaption::before {
      background: rgba(255, 255, 255, 0);
      left: 30px;
      opacity: 1;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
  
    .Show a{
      opacity: 1;
      text-decoration: none;
    }
    
  }

/* BLOG */
#Blogs_Section2 {
  .with-border-bottom{
      border-bottom: 5px solid #264432;
      background-color:var(--Kino_LightDark_green);
    }
}

/* SINGLE BLOG */
.dots{
  i{
    color: var(--Kino_LightDark_green);
  }
}
.list{
  list-style: none;
  padding: 0;
  margin: 0;
}
.list li{
  padding-bottom: 5px;
}
.list li a{
  text-decoration: none;
  color: #fff;
}

#SingleBlog{
  .social-container a i{
      padding-right: 25px;
  }
  .share-button {
      background-color:var(--Kino_LightDark_green);
      aspect-ratio: 1/1;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
  }

  .contact_blog{
      background: linear-gradient(270deg, #00000080 25%, #00000080 55%) , url("../../Resources/Images/background/kinofrontdeskk.jpg") fixed  center;
      background-size: cover;
      padding:0;
      margin: 0;
  }
}


/* CONTACT US */
.form input, .form textarea, .form select {
  background-color: white;
  border-radius: 5px;
  border: solid 1px var(--Kino_LightDark_green);
  width: 100%;
}

.form input:focus, .form textarea:focus, .form select:focus{
  border-color: #264432;
  box-shadow: none;
}

.social-container i{
  color:var(--Kino_LightDark_green);
}


.maps {
  width: 100%;
  height: 480px;
  /* filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg); */
}

#ContactUs{
  .accent-color{
    color: var(--Kino_LightDark_green);
  }
}


/* WHAT HURTS? */
#Whathurts{

  span{
    text-transform: uppercase;
    font-size: 2.85rem;
    color: #484848;
  }

.hurtlist a{
    color:#80AC93;
    text-decoration: none;
    transition: 0.5s ease-in-out;
}
.hurtlist a:hover{
    color:#264432;
    text-decoration: underline;
}
input{
    background-color: white;
    border-radius: 5px;
    border: solid 1px #80AC93;
}
input:focus{
    border-color: #264432;
    box-shadow: none;
}
button:active{
    border-color: #fff;
    box-shadow: none;
}
}

/** TREATMENT */

/** TESTIMONIAL */
#Testimonial{
  .social-container a i{
    padding-right: 25px;
}
.share-button {
    background-color:var(--Kino_LightDark_green);
    aspect-ratio: 1/1;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}
}

/** RESOURCES */
#Resources{

}



/** Animation of AOS Manupulation */
@media screen and (max-width: 1200px) {
  [data-aos-delay="2500"], [data-aos-delay="3000"]{
      transition-delay:0s !important ;
  }
}




/** sideBar */
.sideBar{
  button:focus{
    box-shadow: none;
  }

  .LightDark_Green{
    font-size: 22px;
  }
  ul{
    font-size: 18px;
    padding-top: 20px;
  }
  li{
    background-color: #80AC93;
    border-radius: 20px;
    width: 100%;
  }
  .text{
    border-radius: 20px;
    padding: 10px 0;
    color: #fff;
  }
}

.WhiteColor{
  color:#fff;
}
.iconHeightSideBar {
  height: 25px;
  width: 25px;
  color:#000;
  filter: brightness(0) invert(1);
}


/* Booing an appointment */

.input-control.success input {
  border-color: #09c372;
}

.input-control.error input {
  border-color: #ff3860;
}

.input-control .error {
  color: #ff3860;
  font-size: 0.9rem;
}

#yourInputs{
  height:8rem;
  width: 100%;
}

.centered{
text-align:center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.responsive-width {
  width: 100%; /* Default to full width on mobile screens */
}

/* Media query for larger screens */
@media (min-width: 992px) { /* Adjust the breakpoint as needed */
  .responsive-width {
      width: 50%; /* Example: 50% width on desktops */
  }
}


.disabled-link {
  color: gray;
  pointer-events: none; /* Prevents clicking */
  text-decoration: none; /* Optionally remove underline */
}


.heightV{
  height: 500;
}


.ring{
  background-color: #ececec;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 
  -10px -10px 15px rgba(255,255,255,0.3),
  10px 10px 15px rgba(70,70,70,0.15),
  inset -10px -10px 15px rgba(255,255,255,0.3),
  inset 10px 10px 15px rgba(70,70,70,0.15);
  border: 15px solid #ececec;
  border-radius: 50%;
}
.ring4{
  height: 30px;
  width: 30px;
  animation: glow4 2.5s infinite;
}
@keyframes glow4{
  0%{
      background-color: #ececec;
  }
  20%{
      background-color: #80AC93;
  }
  100%{
      background-color: #80AC93;
  }
}

.ring3{
  height: 120px;
  width: 120px;
  animation: glow3 2.5s infinite;
}
@keyframes glow3{
  20%{
      background-color: #ececec;
  }
  40%{
      background-color: #80AC93;
  }
  100%{
      background-color: #80AC93;
  }
}

.ring2{
  height: 200px;
  width: 200px;
  animation: glow2 2.5s infinite;
}
@keyframes glow2{
  40%{
      background-color: #ececec;
  }
  60%{
      background-color: #80AC93;
  }
  100%{
      background-color: #80AC93;
  }
}

.ring1{
  height: 280px;
  width: 280px;
  animation: glow1 2.5s infinite;
}
@keyframes glow1{
  60%{
      background-color: #ececec;
  }
  80%{
      background-color: #80AC93;
  }
  100%{
      background-color: #80AC93;
  }
}